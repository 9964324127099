*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: 62.5%;
  font-family: "Mulish", sans-serif !important;
  scroll-behavior: smooth;
  width: 100%;
}
.container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}
